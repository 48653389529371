


































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { Loader } from "@googlemaps/js-api-loader";
import validator from "validator";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        city: "",
        address: "",
        postalCode: "",
        point: { lat: 0, lng: 0 },
      }),
    },
  },

  setup(_, { emit, root }) {
    const map = ref<HTMLElement | null>(null);
    const search = ref<HTMLInputElement | null>(null);

    const state = reactive({
      map: null as google.maps.Map | null,
      marker: null as google.maps.Marker | null,
      autocomplete: null as google.maps.places.Autocomplete | null,
    });

    const model = reactive({
      city: "",
      address: "",
      postalCode: "",
      map: false,
      point: { lat: 50.268652173195164, lng: 19.02734801786335 },
    });

    const initMap = () => {
      const loader = new Loader({
        apiKey: "AIzaSyDb2wq84EC-SfWreNnFZ7mbKSGQDPbnBc0",
        version: "weekly",
        libraries: ["places"],
      });

      const mapOptions = {
        center: { lat: 50.268652173195164, lng: 19.02734801786335 },
        zoom: 15,
      };

      loader.load().then(() => {
        state.map = new window.google.maps.Map(
          map.value as HTMLElement,
          mapOptions
        );

        state.marker = new window.google.maps.Marker({
          draggable: true,
          position: mapOptions.center,
          map: state.map,
        });

        state.marker.addListener("drag", () => {
          model.point = {
            lat: state.marker?.getPosition()?.lat() as number,
            lng: state.marker?.getPosition()?.lng() as number,
          };
        });

        state.autocomplete = new window.google.maps.places.Autocomplete(
          (search.value as any).$refs.input as HTMLInputElement
        );
        state.autocomplete.bindTo("bounds", state.map);
        state.autocomplete.addListener("place_changed", () => {
          const place = state.autocomplete?.getPlace();

          state.map?.setCenter(place?.geometry?.location as google.maps.LatLng);
          state.marker?.setPosition(
            place?.geometry?.location as google.maps.LatLng
          );

          model.point = {
            lat: state.marker?.getPosition()?.lat() as number,
            lng: state.marker?.getPosition()?.lng() as number,
          };
        });
      });
    };

    onMounted(initMap);

    watch(
      () => model.point,
      (newPoint) => {
        state.marker?.setPosition(
          new window.google.maps.LatLng(newPoint.lat, newPoint.lng)
        );
      },
      { deep: true }
    );

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          root.$tc("layout.misc.validZipCode"),
      ],
    };

    return { map, search, state, model, rules };
  },
});
